import { VApp } from 'vuetify/lib/components/VApp';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"app"}},[_c('router-view'),_c(VFooter,{attrs:{"padless":"","fixed":""}},[_c(VCard,{staticClass:"grey lighten-4",attrs:{"flat":"","tile":"","width":"100%"}},[_c(VRow,{staticClass:"ma-0 align-center"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"6"}},[_c(VCardText,{staticClass:"d-flex align-center",staticStyle:{"font-size":"0.8rem"}},[_c('p',{staticClass:"d-flex align-self-center mb-0"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Kanton St.Gallen, Entwicklung Small Business Know How GmbH V1.0.0 ")])])],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"6"}},[_c(VSpacer),_c(VCardText,{staticClass:"text-right"},[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){_vm.sheet.legalNotice = true}}},[_vm._v("Impressum")]),_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){_vm.sheet.supportNotice = true}}},[_vm._v("Support")])],1)],1)],1)],1)],1),_c('div',{},[_c(VBottomSheet,{attrs:{"scrollable":""},model:{value:(_vm.sheet.legalNotice),callback:function ($$v) {_vm.$set(_vm.sheet, "legalNotice", $$v)},expression:"sheet.legalNotice"}},[_c('legal-notice',{on:{"closeSheet":function($event){_vm.sheet.legalNotice=false}}})],1)],1),_c('div',{},[_c(VBottomSheet,{attrs:{"scrollable":""},model:{value:(_vm.sheet.supportNotice),callback:function ($$v) {_vm.$set(_vm.sheet, "supportNotice", $$v)},expression:"sheet.supportNotice"}},[_c('support-notice',{on:{"closeSheet":function($event){_vm.sheet.supportNotice=false}}})],1)],1),_c(VSnackbar,{staticClass:"mb-16",attrs:{"timeout":"3500","color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }