import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardText,{staticClass:"mt-6"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Support")]),_c('p',[_vm._v(" Die Webapplikation wird von der Energiefachstelle des Kantons St.Gallen betrieben. Bei inhaltlichen oder technischen Fragen wenden Sie sich bitte per E-Mail an "),_c('a',{attrs:{"href":"mailto:formularsystem@sg.ch"}},[_vm._v("formularsystem@sg.ch")])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('closeSheet')}}},[_vm._v(" Schliessen ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }